<template>
  <CRow>
    <CCol :sm="12">
      <CCard>

        <CCardHeader>
          <h5 v-if="benutzer">
            <CIcon size="lg" name="cil-plus"/>
            Daten ändern für <em>Benutzer {{benutzer.name}}</em>
          </h5>
        </CCardHeader>

        <CCardBody>
          <CForm @submit.prevent="changeBenutzer" autocomplete="off">
            <input-email v-model="form.email" :vld="v$.form.email" label="E-Mail *"/>
            <input-text v-model="form.name" :vld="v$.form.name" label="Name *"/>

            <input-rolle v-model="form.rolle" :vld="v$.form.rolle" label="Rolle/Level *"/>
            <input-box-no v-model="form.boxNo" :vld="v$.form.boxNo" label="Kühlbox-Nr."/>
            <hr/>
            <input-submit class="mt-4" label="Daten ändern" :vld="v$"/>
          </CForm>

          <FormError v-if="formError" :errormessage="formError"/>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { required, email } from '@vuelidate/validators'
import FormError from '@/components/form-elements/Error'
import inputText from '@/components/form-elements/Text'
import inputEmail from '@/components/form-elements/Email'
import inputRolle from '@/components/form-elements/Rolle'
import inputBoxNo from '@/components/form-elements/BoxNo'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'app-user-editieren',
  components: {
    inputText,
    inputEmail,
    inputRolle,
    inputBoxNo,
    inputSubmit,
    FormError
  },
  props: {
    uuid: [Number, String]
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        email: {
          required, email
        },
        rolle: {
          required
        },
        boxNo: {
        }
      }
    }
  },
  data () {
    return {
      formError: null,
      benutzer: null,
      form: {
        name: '',
        email: '',
        rolle: '',
        boxNo: ''
      }
    }
  },
  mounted () {
    this.loadMitarbeiter()
  },
  methods: {
    changeBenutzer () {
      this.axios.put('/user/put/' + this.uuid, {
        name: this.form.name,
        email: this.form.email,
        roles: this.form.roles,
        boxNo: this.form.boxNo
      }).then(() => {
        this.toast.success('Die Nutzerdaten wurde geändert.')
        this.benutzer = null
        this.formError = null
        this.form.name = ''
        this.form.email = ''
        this.form.roles = ''
        this.form.boxNo = ''
        this.$router.push({ path: '/benutzer/uebersicht' })
      }).catch((error) => {
        this.formError = error.response.data
      })
    },
    loadMitarbeiter () {
      if (this.uuid) {
        this.axios.get('/user/get/user/' + this.uuid)
          .then((response) => {
            this.benutzer = response.data[0]
            this.form.name = this.benutzer.name
            this.form.email = this.benutzer.email
            this.form.rolle = this.benutzer.roles
            this.form.boxNo = this.benutzer.boxNo
          })
      }
    }
  }
}
</script>
